<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">   
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">이미지 추가</v-col>
            </v-row>        
        </v-card-title>                 
        <v-card-text class="pb-0">
            <v-row no-gutters class="mt-3 mb-3">
                <v-col>※ 최대 5MB까지 가능하며, 560(가로)X80(세로) 길이의 이미지를 업로드 해주세요.</v-col>            
            </v-row>     
            <v-row no-gutters class="mt-3" v-if="!imageFile">
                <v-col class="text-center">
                    <image-input-card v-model="imageFile" v-on:input="selectImage" style="width:100%; height:80px;">
                        <div slot="activator">
                            <v-card flat outlined class="text-center pa-0 ma-0 pt-1" hover style="cursor:pointer; background-color:#FFFFBB">
                                <v-card-text style="font-size:18px; font-weight:600;">+<br/>이미지 추가</v-card-text>
                            </v-card>
                        </div>
                    </image-input-card>                        
                </v-col>
            </v-row>             
            <v-row no-gutters class="mt-3" v-if="imageFile">
                <v-col class="text-center">
                    <div class="canvas-wrapper" style="width:100%; height:300px;">
                        <canvas ref="canvas"></canvas>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-3" v-if="imageFile">
                <v-col>
                    * 이미지를 드래그하여 위치, 크기를 변경할 수 있습니다.<br/>
                    * 파란 점선 영역만 등록되어 사용됩니다.
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
                <v-col class="text-left pt-0 mb-0 pb-0" v-if="imageFile" style="min-width:250px;">
                    <v-btn class="ma-2" color="primary" icon v-on:click="moveAndResizeImgToFit()"><v-icon>mdi-crop-free</v-icon></v-btn>
                    <v-btn class="ma-2" color="primary" icon v-on:click="moveAndResizeImgToFit('h')"><v-icon>mdi-arrow-expand-horizontal</v-icon></v-btn>
                    <v-btn class="ma-2" color="primary" icon v-on:click="moveAndResizeImgToFit('v')"><v-icon>mdi-arrow-expand-vertical</v-icon></v-btn>                    
                    <v-btn class="ma-2" color="primary" icon v-on:click="rotateImg"><v-icon>mdi-rotate-right</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters class="mt-3" >
                <v-col cols="12" class="pr-5 pb-3 pt-3 text-right block-bt">
                    <v-btn class="ma-2" width="130px" color="primary" text  v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="doCrop()" v-if="imageFile"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>       

    </v-card>          
</template>

<script>
import svcWvApi from '@/api/svc_wv'

export default {
    data: () => ({
        cropDestSize: { width: 560, height: 80 },
        url: '',
        imageFile: null,   
        canvas: null,
        cctx: null,
        imgCanvas: null,
        img: null,
        cropGuideRect: { top:0, left:0, width: 560, height: 80 },
        imgViewRect: { top: 0, left: 0, width: 0, height: 0 },
        imgViewRotate: 0,
        mousePos: { x: 0, y: 0 }, 
        dragStart: { x: 0, y: 0 },
        dragStartRect: { top: 0, left: 0, width: 0, height: 0 },
        dragTo: { x: 0, y: 0 },
        pZoomStartRect: { top: 0, left: 0, width: 0, height: 0 },
        pZoomLastRect: { top: 0, left: 0, width: 0, height: 0 },
        pZoomStartViewRect: { top: 0, left: 0, width: 0, height: 0 },
        grabType: '',
        isDragging: false
    }),
    components: {
        'imageInputCard': () => import('@/components/svc/wv/tile_image_input'),
    },    
    mounted() { 
                
    },
    beforeDestroy() {
        if(this.canvas) { 
            this.canvas.removeEventListener('mousedown', this.mouseDownInCanvas)
            this.canvas.removeEventListener('touchstart', this.touchStartInCanvas)
        }

        window.removeEventListener('resize', this.windowResize)
    },
    methods: {                
        closePopup() { 
            this.$emit('cancel')
        },  

        selectImage (payload) { 
            console.log('selectImage() : ', payload)

            if (payload && payload.imageFile) {
                let reader = new FileReader()
                reader.onload = (e) => { 
                    this.cropperInitFlag = false
                    this.cropperImage = e.target.result
                }
                reader.readAsDataURL(payload.imageFile)
                this.imageFile = payload.imageFile
                this.imageFile.crossOrigin = 'Anonymous'

                let ctx = this
                setTimeout(function() { 
                    ctx.initCanvas(payload.imageURL)
                }, 500)
            }
        },      

        initCanvas(imageURL) { 
            console.log('init canvas')

            // load a image
            this.loadImg(imageURL, () => { 
                // main canvas
                this.canvas = this.$refs['canvas']
                this.cctx = this.canvas.getContext("2d"); 
                
                // resize main canvas 
                this.calcCanvasAndGuide()            

                this.moveAndResizeImgToFit()

                this.canvas.addEventListener('mousedown', this.mouseDownInCanvas, { passive: false, })
                this.canvas.addEventListener('touchstart', this.touchStartInCanvas, { passive: false, })

                this.canvas.style.cursor = 'default'

                window.addEventListener('resize', this.windowResize)

            })


        },
        windowResize() { 
            let lastWidth = this.canvas.width 
            let lastHeight = this.canvas.height 
            let newWidth = this.canvas.parentElement.clientWidth 
            let newHeight = this.canvas.parentElement.clientHeight 

            if(lastWidth != newWidth || lastHeight != newHeight) { 
                console.log('resized: (' + this.canvas.width + ', ' + this.canvas.height + ') -> (' + this.canvas.parentElement.clientWidth + ', ' + this.canvas.parentElement.clientHeight + ')')
                console.log('imgViewRect_FROM: (top=' + this.imgViewRect.top + ', left=' + this.imgViewRect.left + ', width=' + this.imgViewRect.width + ', height=' + this.imgViewRect.height + ')')

                // 560 x 400
                let backCanvasWidth = 560
                let backCanvasHeight = 400

                let lastRatio = backCanvasWidth / lastWidth
                let newRatio = backCanvasWidth / newWidth 

                let rectOnBack = {
                    top: this.imgViewRect.top, 
                    left: this.imgViewRect.left * lastRatio,
                    width: this.imgViewRect.width * lastRatio, 
                    height: this.imgViewRect.height * lastRatio
                }

                let rectOnNew = { 
                    top: rectOnBack.top,
                    left: rectOnBack.left / newRatio, 
                    width: rectOnBack.width / newRatio, 
                    height: rectOnBack.height / newRatio
                }

                rectOnNew.top = newHeight / 2 + rectOnNew.height / 2 - (lastHeight / 2 - this.imgViewRect.top + this.imgViewRect.height / 2) * (lastRatio / newRatio)


                this.calcCanvasAndGuide()
                // this.moveAndResizeImgToFit()

                this.imgViewRect.top = rectOnNew.top
                this.imgViewRect.left = rectOnNew.left 
                this.imgViewRect.width = rectOnNew.width 
                this.imgViewRect.height = rectOnNew.height

                console.log('imgViewRect_TO: (top=' + this.imgViewRect.top + ', left=' + this.imgViewRect.left + ', width=' + this.imgViewRect.width + ', height=' + this.imgViewRect.height + ')')

                this.redrawCanvas()                
            }
        },
        calcCanvasAndGuide() { 
            console.log('calcCanvasAndGuide()')

            // resize main canvas 
            this.canvas.width = this.canvas.parentElement.clientWidth
            this.canvas.height = this.canvas.parentElement.clientHeight


            let canvasWidth = this.canvas.width
            let canvasHeight = this.canvas.height

            console.log('canvasSize=(w:' + canvasWidth + ', h:' + canvasHeight + ')')

            // resize crop guide
            let cropDestWidth = this.cropDestSize.width 
            let cropDestHeight = this.cropDestSize.height 

            // if screen is too small, resize crop_guide
            if(canvasWidth < cropDestWidth) { 
                this.cropGuideRect.width = canvasWidth
                this.cropGuideRect.height = canvasWidth * (cropDestHeight / cropDestWidth)
                console.log('resize cropGuideRect: width='+this.cropGuideRect.width+', height='+this.cropGuideRect.height)
            } else { 
                this.cropGuideRect.width = cropDestWidth 
                this.cropGuideRect.height = cropDestHeight
            }

            this.cropGuideRect.top = (canvasHeight - this.cropGuideRect.height) / 2
            this.cropGuideRect.left = (canvasWidth - this.cropGuideRect.width) / 2
            console.log('cropGuideRect=(top:'+this.cropGuideRect.top+', left:'+this.cropGuideRect.left+', width:'+this.cropGuideRect.width+', height:'+this.cropGuideRect.height+')')

        },
        loadImg(imageURL, scb) { 
            let img = new Image();
            img.crossOrigin = "Anonymous";            
            this.img = img

            let vctx = this
            img.onload = function(){

                // make img canvas 
                vctx.imgCanvas = document.createElement('canvas')
                vctx.imgCanvas.width = img.width
                vctx.imgCanvas.height = img.height
                vctx.imgCanvas.getContext('2d').drawImage(vctx.img, 0, 0, img.width, img.height)

                if(scb) { 
                    scb()
                }                
            }
            this.img.src = imageURL
        },

        redrawCanvas() { 
            if(!this.cctx){ return }

            console.log('draw canvas')
            this.drawImageLayer(this.cctx)
            this.drawGuideLayer(this.cctx)
        },

        drawImageLayer(ctx) { 
            // clear main canvas
            ctx.fillStyle = "rgb(255, 255, 255, 1)"
            ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

            //console.log('drawImage: imgView.width=[' + this.imgViewRect.width + '], imgView.height=[' + this.imgViewRect.height + '], ratio=[' + (this.imgViewRect.width / this.imgViewRect.height) + ']')
            ctx.drawImage(this.imgCanvas, this.imgViewRect.left, this.imgViewRect.top, this.imgViewRect.width, this.imgViewRect.height)

            ctx.strokeStyle = (this.isDragging && this.grabType == 'IMG') ? 'rgba(255, 0, 0, 1)' : 'rgba(0, 0, 0, 1)'
            ctx.setLineDash([])
            ctx.lineWidth = 1
            ctx.strokeRect(this.imgViewRect.left-0.5, this.imgViewRect.top-0.5, this.imgViewRect.width, this.imgViewRect.height)


            ctx.fillStyle = (this.isDragging && this.grabType == 'IMG_TOP_LEFT') ? 'rgba(255, 0, 0, 1)' : 'rgb(0, 0, 0, 1)'
            let r = this.calcHandleRect(this.imgViewRect, 'top_left')
            ctx.fillRect(r.left, r.top, r.width, r.height)

            ctx.fillStyle = (this.isDragging && this.grabType == 'IMG_TOP_RIGHT') ? 'rgba(255, 0, 0, 1)' : 'rgb(0, 0, 0, 1)'
            r = this.calcHandleRect(this.imgViewRect, 'top_right')
            ctx.fillRect(r.left, r.top, r.width, r.height)

            ctx.fillStyle = (this.isDragging && this.grabType == 'IMG_BOTTOM_LEFT') ? 'rgba(255, 0, 0, 1)' : 'rgb(0, 0, 0, 1)'
            r = this.calcHandleRect(this.imgViewRect, 'bottom_left')
            ctx.fillRect(r.left, r.top, r.width, r.height)

            ctx.fillStyle = (this.isDragging && this.grabType == 'IMG_BOTTOM_RIGHT') ? 'rgba(255, 0, 0, 1)' : 'rgb(0, 0, 0, 1)'
            r = this.calcHandleRect(this.imgViewRect, 'bottom_right')
            ctx.fillRect(r.left, r.top, r.width, r.height)

        },
        drawGuideLayer(ctx) { 

            let canvasWidth = this.canvas.width
            let canvasHeight = this.canvas.height 
            
            ctx.fillStyle = "rgb(0, 0, 0, 0.4)"

            let guideBorder = {
                h: (canvasHeight - this.cropGuideRect.height) / 2,
                w: (canvasWidth - this.cropGuideRect.width) / 2
            }

//            console.log('canvas=(w:' + canvasWidth + ', h:' + canvasHeight + ')')
//            console.log('guideBorder=(w:' + guideBorder.w + ', h:' + guideBorder.h + ')')

            ctx.fillRect(0, 0, guideBorder.w, canvasHeight)
            ctx.fillRect(canvasWidth - guideBorder.w, 0, guideBorder.w, canvasHeight)
            ctx.fillRect(guideBorder.w, 0, canvasWidth - guideBorder.w * 2, guideBorder.h)
            ctx.fillRect(guideBorder.w, canvasHeight - guideBorder.h, canvasWidth - guideBorder.w * 2, guideBorder.h)

            ctx.strokeStyle = 'rgba(0, 153, 255, 1)'
            ctx.setLineDash([5, 3])
            ctx.lineWidth = 2
            ctx.strokeRect(this.cropGuideRect.left, this.cropGuideRect.top, this.cropGuideRect.width, this.cropGuideRect.height)
        },
        posInRect(p, r, o) { 
            if(!p || !r) { return false }
            if(!o) { 
                let o = 0 
            }

            if(p.x >= r.left - o && p.x <= r.left+r.width + o && p.y >= r.top - o && p.y <= r.top+r.height + o) { 
                return true
            }
            return false
        },
        calcHandleRect(r, t) { 
            if(t == 'top_left') { 
                return {'left': r.left - 5.5, 'top': r.top - 5.5, 'width': 10, 'height': 10}
            } else if(t == 'top_right') { 
                return {'left': r.left + r.width - 5.5, 'top': r.top - 5.5, 'width': 10, 'height': 10}
            } else if(t == 'bottom_left') { 
                return {'left': r.left - 5.5, 'top': r.top + r.height - 5.5, 'width': 10, 'height': 10}
            } else if(t == 'bottom_right') { 
                return {'left': r.left + r.width - 5.5, 'top': r.top + r.height - 5.5, 'width': 10, 'height': 10}
            }
            return {'left': 0, 'top': 0, 'width': 0, 'height': 0}        
        },
        moveAndResizeImgToFit(fitType) { 
            let cropGuideRatio = this.cropGuideRect.width / this.cropGuideRect.height 
            let imgRatio = this.imgCanvas.width / this.imgCanvas.height

            if(!fitType) { 
                if(cropGuideRatio < imgRatio) { 
                    fitType = 'va'
                } else {
                    fitType = 'ha'
                }
            }

            if(fitType == 'va') { 
                // fit with crop guide height
                this.imgViewRect.height = this.cropGuideRect.height
                this.imgViewRect.width = imgRatio * this.imgViewRect.height
                this.imgViewRect.top = this.cropGuideRect.top
                this.imgViewRect.left = this.cropGuideRect.left - ((this.imgViewRect.width - this.cropGuideRect.width) / 2)
            } else if(fitType == 'ha') { 
                // fit with crop guide width 
                this.imgViewRect.width = this.cropGuideRect.width 
                this.imgViewRect.height = this.imgViewRect.width / imgRatio
                this.imgViewRect.top = this.cropGuideRect.top - ((this.imgViewRect.height - this.cropGuideRect.height) / 2)
                this.imgViewRect.left = this.cropGuideRect.left
            } else if(fitType == 'v') { 
                // fit with crop guide height, does not change left
                this.imgViewRect.height = this.cropGuideRect.height
                this.imgViewRect.left += this.imgViewRect.width / 2
                this.imgViewRect.width = imgRatio * this.imgViewRect.height
                this.imgViewRect.left -= this.imgViewRect.width / 2
                this.imgViewRect.top = this.cropGuideRect.top
            } else if(fitType == 'h') { 
                // fit with crop guide width, does not change top
                this.imgViewRect.width = this.cropGuideRect.width 
                this.imgViewRect.top += this.imgViewRect.height / 2
                this.imgViewRect.height = this.imgViewRect.width / imgRatio
                this.imgViewRect.top -= this.imgViewRect.height / 2
                this.imgViewRect.left = this.cropGuideRect.left
            }

            this.redrawCanvas()
        },
        touchStartInCanvas(evt) {      
            console.log('touchStartInCanvas : '+evt.touches.length)  
            let touchCnt = evt.touches.length
            if(touchCnt > 1) { 
                this.startPinchZoom(evt.touches[0].clientX, evt.touches[0].clientY, evt.touches[1].clientX, evt.touches[1].clientY)
            }
                
            let touch = evt.touches[0];
            let mouseEvent = new MouseEvent("mousedown", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            this.canvas.dispatchEvent(mouseEvent);
            
            window.addEventListener('touchmove', this.touchMoveDragging, {passive:false})
            window.addEventListener('touchend', this.touchEndDragging, false)
            this.canvas.removeEventListener('touchstart', this.touchStartInCanvas, false)


            if (evt.preventDefault) {
                evt.preventDefault();
            }
            evt.stopPropagation();
        },
        startPinchZoom(t1X, t1Y, t2X, t2Y) { 
            console.log('touchStartInCanvas : START PINCH ZOOM')

            let canvasBoundingRect = this.canvas.getBoundingClientRect();
            
            console.log('touch: (' + (t1X - canvasBoundingRect.left) + ', ' + (t1Y - canvasBoundingRect.top) + ') - (' + (t2X - canvasBoundingRect.left) + ', ' + (t2Y - canvasBoundingRect.top) + ')')

            this.pZoomStartRect.top = t1Y - canvasBoundingRect.top
            this.pZoomStartRect.left = t1X - canvasBoundingRect.left
            this.pZoomStartRect.width = t2X - t1X
            this.pZoomStartRect.height = t2Y - t1Y

            this.pZoomLastRect.top = this.pZoomStartRect.top
            this.pZoomLastRect.left = this.pZoomStartRect.left
            this.pZoomLastRect.width = this.pZoomStartRect.width 
            this.pZoomLastRect.height = this.pZoomStartRect.height

            this.pZoomStartViewRect.top = this.imgViewRect.top
            this.pZoomStartViewRect.left = this.imgViewRect.left
            this.pZoomStartViewRect.width = this.imgViewRect.width 
            this.pZoomStartViewRect.height = this.imgViewRect.height

        },
        calcPinchZoom(t1X, t1Y, t2X, t2Y) {
            let canvasBoundingRect = this.canvas.getBoundingClientRect();

            this.pZoomLastRect.top = t1Y - canvasBoundingRect.top
            this.pZoomLastRect.left = t1X - canvasBoundingRect.left
            this.pZoomLastRect.width = t2X - t1X
            this.pZoomLastRect.height = t2Y - t1Y
            
            let zoomStartCenter = {
                x: this.pZoomStartRect.left + this.pZoomStartRect.width / 2,
                y: this.pZoomStartRect.top + this.pZoomStartRect.height / 2
            }
            let zoomDestCenter = {
                x: this.pZoomLastRect.left + this.pZoomLastRect.width / 2,
                y: this.pZoomLastRect.top + this.pZoomLastRect.height / 2
            }

            let zoomVal = Math.sqrt(Math.abs(this.pZoomLastRect.width * this.pZoomLastRect.height)) - Math.sqrt(Math.abs(this.pZoomStartRect.width * this.pZoomStartRect.height))
            //console.log('applyPinchZoom : zoomVal=' + zoomVal)
            zoomVal = zoomVal * 1.5

            if(zoomVal < this.pZoomStartViewRect.width * -0.9) { 
                zoomVal = this.pZoomStartViewRect.width * -0.9
            }

            this.imgViewRect.width = this.pZoomStartViewRect.width + zoomVal
            this.imgViewRect.height = this.pZoomStartViewRect.height * this.imgViewRect.width / this.pZoomStartViewRect.width
            this.imgViewRect.left = this.pZoomStartViewRect.left + zoomDestCenter.x - zoomStartCenter.x - ((zoomStartCenter.x - this.pZoomStartViewRect.left) * this.imgViewRect.width / this.pZoomStartViewRect.width - (zoomStartCenter.x - this.pZoomStartViewRect.left))
            this.imgViewRect.top = this.pZoomStartViewRect.top + zoomDestCenter.y - zoomStartCenter.y - ((zoomStartCenter.y - this.pZoomStartViewRect.top) * this.imgViewRect.height / this.pZoomStartViewRect.height - (zoomStartCenter.y - this.pZoomStartViewRect.top))

        },   
        mouseDownInCanvas(evt) { 
            //getting mouse position correctly 
            let bRect = this.canvas.getBoundingClientRect();
            
            this.mousePos.x = (evt.clientX - bRect.left)*(this.canvas.width/bRect.width);
            this.mousePos.y = (evt.clientY - bRect.top)*(this.canvas.height/bRect.height);


            if(this.posInRect(this.mousePos, this.calcHandleRect(this.imgViewRect, 'top_left'), 10)) {
                this.grabType = 'IMG_TOP_LEFT'

            } else if(this.posInRect(this.mousePos, this.calcHandleRect(this.imgViewRect, 'top_right'), 10)) {
                this.grabType = 'IMG_TOP_RIGHT'

            } else if(this.posInRect(this.mousePos, this.calcHandleRect(this.imgViewRect, 'bottom_left'), 10)) {
                this.grabType = 'IMG_BOTTOM_LEFT'

            } else if(this.posInRect(this.mousePos, this.calcHandleRect(this.imgViewRect, 'bottom_right'), 10)) {
                this.grabType = 'IMG_BOTTOM_RIGHT'

            } else if(this.posInRect(this.mousePos, this.imgViewRect)) { 
                this.grabType = 'IMG'

            } else {
                this.grabType = ''
            }
            
            if(this.grabType != '') { 
                this.isDragging = true

                this.dragStart.x = this.mousePos.x 
                this.dragStart.y = this.mousePos.y
                this.dragTo.x = this.mousePos.x
                this.dragTo.y = this.mousePos.y 
                this.dragStartRect.left = this.imgViewRect.left
                this.dragStartRect.top = this.imgViewRect.top
                this.dragStartRect.width = this.imgViewRect.width 
                this.dragStartRect.height = this.imgViewRect.height

                this.redrawCanvas()
            }


            window.addEventListener('mousemove', this.mouseMoveDragging, false)
            window.addEventListener('mouseup', this.mouseUpDragging, false)

            this.canvas.removeEventListener('mousedown', this.mouseDownInCanvas, false)            
        },
        touchMoveDragging (evt) { 
            console.log('touchMoveDragging : ' + evt.touches.length)
            
            let touchCnt = evt.touches.length 

            if(touchCnt > 1) { 
                if(this.pZoomStartRect.width == 0 && this.pZoomStartRect.height == 0) { 
                    this.startPinchZoom(evt.touches[0].clientX, evt.touches[0].clientY, evt.touches[1].clientX, evt.touches[1].clientY)
                }

                this.calcPinchZoom(evt.touches[0].clientX, evt.touches[0].clientY, evt.touches[1].clientX, evt.touches[1].clientY)

                this.redrawCanvas()
            } else { 

                let touch = evt.touches[0]
                let mouseEvent = new MouseEvent('mousemove', {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                //console.log('touchMoveDragging : clientX=' + touch.clientX + ', clientY=' + touch.clientY, evt)       
                window.dispatchEvent(mouseEvent)
            }

            if (evt.preventDefault) {
                evt.preventDefault();
            }
            evt.stopPropagation();
        },
        mouseMoveDragging (evt) { 
            let bRect = this.canvas.getBoundingClientRect();
            
            this.mousePos.x = (evt.clientX - bRect.left)*(this.canvas.width/bRect.width);
            this.mousePos.y = (evt.clientY - bRect.top)*(this.canvas.height/bRect.height);         

            if(this.isDragging && this.grabType != '') { 
                this.dragTo.x = this.mousePos.x
                this.dragTo.y = this.mousePos.y

                let deltaX = this.dragTo.x - this.dragStart.x 
                let deltaY = this.dragTo.y - this.dragStart.y 

                if(deltaX != 0 && deltaY != 0) { 
                    //console.log('dragging: [' + this.grabType + '] DELTA(' + deltaX + ', ' + deltaY + ')')
                    let imgRatio = this.img.width / this.img.height

                    if(this.grabType == 'IMG') { 
                        // move image
                        this.imgViewRect.left = this.dragStartRect.left + deltaX 
                        this.imgViewRect.top = this.dragStartRect.top + deltaY 

                    } else if(this.grabType == 'IMG_TOP_LEFT') {
                        // resize IMG_TOP_LEFT
                        if(deltaX > this.dragStartRect.width - 30) { deltaX = this.dragStartRect.width - 30 }
                        deltaY = deltaX / imgRatio 

                        this.imgViewRect.left = this.dragStartRect.left + deltaX
                        this.imgViewRect.top = this.dragStartRect.top + deltaY
                        this.imgViewRect.width = this.dragStartRect.width - deltaX
                        this.imgViewRect.height = this.dragStartRect.height - deltaY

                    } else if(this.grabType == 'IMG_TOP_RIGHT') {
                        // resize IMG_TOP_RIGHT
                        if(deltaX * -1 > this.dragStartRect.width - 30) { deltaX = (this.dragStartRect.width - 30) * -1 }
                        deltaY = deltaX / imgRatio * -1

                        this.imgViewRect.top = this.dragStartRect.top + deltaY
                        this.imgViewRect.width = this.dragStartRect.width + deltaX
                        this.imgViewRect.height = this.dragStartRect.height - deltaY
                        
                    } else if(this.grabType == 'IMG_BOTTOM_LEFT') {
                        // resize IMG_BOTTOM_LEFT
                        if(deltaX > this.dragStartRect.width - 30) { deltaX = this.dragStartRect.width - 30 }
                        deltaY = deltaX / imgRatio * -1

                        this.imgViewRect.left = this.dragStartRect.left + deltaX
                        this.imgViewRect.width = this.dragStartRect.width - deltaX
                        this.imgViewRect.height = this.dragStartRect.height + deltaY

                    } else if(this.grabType == 'IMG_BOTTOM_RIGHT') {
                        // resize IMG_BOTTOM_RIGHT 
                        if(deltaX * -1 > this.dragStartRect.width - 30) { deltaX = (this.dragStartRect.width - 30) * -1 }
                        deltaY = deltaX / imgRatio

                        this.imgViewRect.width = this.dragStartRect.width + deltaX
                        this.imgViewRect.height = this.dragStartRect.height + deltaY
                                            
                    }

                    if(this.grabType != '') { 
                        this.redrawCanvas()                        
                    }
                }
                
            }
        },
        touchEndDragging (evt) { 
            //console.log('touchEndDragging')       
            let mouseEvent = new MouseEvent('mouseup', {})
            window.dispatchEvent(mouseEvent);

            this.canvas.addEventListener('touchstart', this.touchStartInCanvas, { passive: false, })
            window.removeEventListener('touchmove', this.touchMoveDragging)
            window.removeEventListener('touchend', this.touchEndDragging)

            this.pZoomStartRect.top = 0
            this.pZoomStartRect.left = 0 
            this.pZoomStartRect.width = 0 
            this.pZoomStartRect.height = 0 

            if (evt.preventDefault) {
                evt.preventDefault();
            }
            evt.stopPropagation();
        },
        mouseUpDragging (evt) { 
            console.log('dragged: [' + this.grabType + ']')

            this.isDragging = false
            this.grabType = ''

            this.canvas.addEventListener('mousedown', this.mouseDownInCanvas, { passive: false, })
            window.removeEventListener('mousemove', this.mouseMoveDragging)
            window.removeEventListener('mouseup', this.mouseUpDragging)


            this.redrawCanvas()
        },
        rotateImg () { 
            console.log('rotateImg()')

            this.imgViewRotate += 90
            if(this.imgViewRotate >= 360) { 
                this.imgViewRotate = 0
            }

            // make rotated img canvas 
            let rotImgCanvas = document.createElement('canvas')
            rotImgCanvas.width = this.imgCanvas.height
            rotImgCanvas.height = this.imgCanvas.width

            let ctx = rotImgCanvas.getContext('2d')
            ctx.save()
            ctx.translate(rotImgCanvas.width, 0)
            ctx.rotate(90 * Math.PI / 180)
            ctx.drawImage(this.imgCanvas, 0, 0, this.imgCanvas.width, this.imgCanvas.height)
            ctx.restore()
            
            this.imgCanvas = rotImgCanvas
            let t = this.imgViewRect.width 
            this.imgViewRect.width = this.imgViewRect.height
            this.imgViewRect.height = t

            console.log('rotateImg: width=' + this.imgViewRect.width + ', height=' + this.imgViewRect.height)
            this.redrawCanvas()

            /*
                ctx.save()
                console.log('drawImage: translate(x=[' + this.imgViewRect.left + '], y=[' + this.imgViewRect.top + ']), (width=[' + this.imgViewRect.width + '], height=[' + this.imgViewRect.height + '])')
                ctx.translate(this.imgViewRect.left+this.imgViewRect.width/2, this.imgViewRect.top+this.imgViewRect.height/2)
                ctx.rotate(this.imgViewRotate * Math.PI / 180)
                ctx.drawImage(this.img, this.imgViewRect.width/2*-1, this.imgViewRect.height/2*-1, this.imgViewRect.width, this.imgViewRect.height)
                ctx.restore()
            }
            */
        },
        doCrop () { 
            console.log('doCrop()')


            // 560 x 400
            let backCanvas = document.createElement('canvas')
            backCanvas.width = 560
            backCanvas.height = 400

            let posRatio = 560 / this.canvas.width
            backCanvas.getContext('2d').drawImage(this.img, this.imgViewRect.left * posRatio, this.imgViewRect.top * posRatio, this.imgViewRect.width * posRatio, this.imgViewRect.height * posRatio)

            let cropCanvas = document.createElement('canvas');
            cropCanvas.width = 560;
            cropCanvas.height = 80;
            cropCanvas.getContext('2d').drawImage(backCanvas, 
                                this.cropGuideRect.left * posRatio, this.cropGuideRect.top * posRatio, this.cropGuideRect.width * posRatio, this.cropGuideRect.height * posRatio, 
                                0, 0, 560, 80)

            let dataUrl = cropCanvas.toDataURL('image/png')
            let blob = this.dataURItoBlob(dataUrl)
            let cropCoord = { top: 0, left: 0, width: 560, height: 80 }
            
            svcWvApi.uploadLogoImage(blob, cropCoord, (data) => {
                console.log('SUCCESS!! : content_url=[' + data.content_url + ']');
                if(data.content_url){ 
                    this.url = data.content_url
                }

                this.$emit('submit', this.url)

            }, (err) => {
                console.log('FAILURE!!');
                console.log(err);

                if(this.$noSessionProc(err)){ return }
                alert("이미지 등록을 실패 하였습니다")
            })   
        },

        dataURItoBlob (dataURI) {
            let byteString = atob(dataURI.split(',')[1]);
            let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++)
            {
                ia[i] = byteString.charCodeAt(i);
            }

            let bb = new Blob([ab], { "type": mimeString });
            return bb;
        }        

    }
}
</script>

<style>
canvas { border: 1px solid #A0A0A0; }
</style>